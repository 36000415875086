import React from "react";
import Footer from "../components/footer";
import grain from "../assets/Grain.jpg";
import pdf from "../assets/Grainpac_Credit_Form.pdf";
import RatePDF from "../assets/Grainpac_Melbourne_Rates.pdf";
import TCPDF from "../assets/Grainpac_Melbourne_T&C.pdf";
import { ServiceCards } from "./Services";
import Hero from "../components/Hero";

function PackingRates() {
  const crumb = {
    Home: ["Home", "/"],
    Services: ["Services", "/services"],
    PackingRates: ["Packing Rates", "/services/packing_rates"],
  };

  const rates = [
    {
      product: "Cereals",
      commodity: ["Wheat", "Barley", "Maize (21 tonnes)", "Maize (25 tonnes)"],
      tonnes: [25.0, 22.5, 21.0, 25.0],
      rates20: ["$33.00", "$36.00", "$39.00", "$33.00"],
      rates40: ["$35.00", "$35.00", "n/a", "$35.00"],
    },
    {
      product: "Other",
      commodity: ["Canola"],
      tonnes: [21.0],
      rates20: ["$39.00"],
      rates40: ["$35.00"],
    },
    {
      product: "Pulses",
      commodity: [
        "Chick Peas",
        "Faba Beans",
        "Field Peas",
        "Lentils",
        "Lupins",
      ],
      tonnes: [24.0, 24.0, 25.0, 25.0, 24.0],
      rates20: ["$36.00", "$36.00", "$36.00", "$36.00", "$36.00"],
      rates40: ["$37.50", "$37.50", "$37.50", "$37.50", "$37.50"],
    },
  ];

  return (
    <div className="bg-white relative">
      {/* Hero Section with NavBar overlay */}
      <Hero
        type={"sub"}
        img={grain}
        title={"PACKING RATES"}
        subText={"Seamless Container Solutions for an affordable price"}
        crumb={crumb}
      />

      {/* Main Content */}
      <div className="container mx-auto py-12 px-10 w-[90%]">
        <h2 className="text-3xl font-bold mb-6 text-slate-700">
          CONTAINER PACKING Schedule of Rates
        </h2>
        <div className="container mx-auto my-10 px-4">
          {/* Container Packing Rates Table */}
          <p className="text-[#0071bc]">
            Receival, Storage, Containerisation & Freight to Port of Melbourne
          </p>
          {/* Wrapping table in a responsive scroll container */}
          <div className="overflow-xl-auto">
            <table className="w-full mt-4 border border-gray-300 text-sm sm:text-base">
              <thead className="bg-gray-200">
                <tr>
                  <th className="border border-gray-300 p-2">Product</th>
                  <th className="border border-gray-300 p-2">Commodity</th>
                  <th className="border border-gray-300 p-2">
                    Avg. Tonnes per 20' Container
                  </th>
                  <th className="border border-gray-300 p-2">
                    per Tonne 20' Containers
                  </th>
                  <th className="border border-gray-300 p-2">
                    per Tonne 40' Containers
                  </th>
                </tr>
              </thead>
              <tbody>
                {rates.map((rate, index) =>
                  rate.commodity.map((com, i) => (
                    <tr key={i} className="border-b">
                      {i === 0 && (
                        <td
                          className="border border-gray-300 p-2"
                          rowSpan={rate.commodity.length}
                        >
                          {rate.product}
                        </td>
                      )}
                      <td className="border border-gray-300 p-2">{com}</td>
                      <td className="border border-gray-300 p-2">
                        {rate.tonnes[i]}
                      </td>
                      <td className="border border-gray-300 p-2">
                        {rate.rates20[i]}
                      </td>
                      <td className="border border-gray-300 p-2">
                        {rate.rates40[i]}
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
          <p className="mt-4">
            In addition to the provided packing rates, we offer a complete set
            of pricing details for all our services, including fumigation,
            shrink rates, empty container park fees, and terminal fees. To
            access the full breakdown, including additional rates and charges,
            download the comprehensive rate list by clicking the link below.
            <br />
            <a
              href={RatePDF}
              className="text-blue-600 underline"
              download="Grainpac_Melbourne_Schedule_Of_Rates.pdf"
            >
              [Download Full Rate List (PDF)]
            </a>
          </p>
          <p className="mt-4">
            Contact Grainpac Melbourne for Current Rates and Packing Space
            Availability.
          </p>
          <a
            href={pdf}
            className="text-blue-600 underline"
            download="Grainpac_Melbourne_Credit_Application_Form.pdf"
          >
            Grainpac Melbourne — Credit Application Form
          </a>
          <div className="max-w-[1300px] mx-auto my-5 flex justify-center lg:justify-start">
            <a
              href="tel:0383607566"
              className="flex items-center px-2 py-2 bg-[#0071bc] text-white font-normal rounded-md shadow-md hover:bg-black transition duration-200"
            >
              <i className="fas fa-phone-alt mr-2"></i>
              Contact Us
            </a>
          </div>

          {/* What's Included Section */}
          <h2 className="text-xl font-bold mt-8">
            What's Included in the Packing Rates
          </h2>
          <ul className="list-disc ml-6 mt-4">
            <li>95 tonne x 28 metre weighbridge onsite</li>
            <li>
              Classification of grain received in for packing per GTA commodity
              standards
            </li>
            <li>
              Pick up of empty container(s) from designated Melbourne container
              parks and delivery to Grainpac Melbourne facility
            </li>
            <li>
              Onsite Dept. of Agriculture empty container inspection, container
              approval and export compliance documentation (where applicable)
            </li>
            <li>
              Supply and fitting of container bulkheads (where applicable) and
              bolt seals
            </li>
            <li>
              Packing list, weighbridge tickets, fumigation certificate (where
              applicable)
            </li>
            <li>
              Sampling, testing and classification of each individual container
            </li>
            <li>
              Wharf documentation (PRA) and return of packed container(s) to
              designated container terminal in Melbourne
            </li>
          </ul>

          <h2 className="text-xl font-bold mt-8">
            Additional Charges (At Cost)
          </h2>
          <ul className="list-disc ml-6 mt-4">
            <li>
              Empty container park fees, terminal timeslot booking fee, port
              infrastructure surcharge / terminal access fee.{" "}
            </li>
          </ul>

          {/* Terms and Conditions Section */}
          <h2 className="text-xl font-bold mt-8">
            Standard Terms and Conditions
          </h2>
          <ul className="list-disc ml-6 mt-4">
            <li>
              Farmer’s dressed bulk only. Grain is comingled on a
              grade-for-grade basis unless requested otherwise – additional
              charges may apply where clients request standalone segregations.
              Shrinkage rate of 0.2% applies for all cereals and 0.4% applies
              for all pulses.
            </li>
            <li>
              Availability of packing space must be confirmed by Grainpac
              Melbourne prior to booking being accepted. Packing instructions
              for confirmed bookings must be received fourteen (14) days prior
              to the first date of receivals for the nominated vessel, unless
              agreed otherwise. Such information to include commodity, grade,
              tonnage, contract reference, sample requirements, name of shipping
              line, booking number, vessel, voyage number, empty release depot,
              discharge port, and final destination. If packing instructions
              with vessel details are not received within the timeframe, the
              space is subject to reconfirmation. Any amendments to packing
              instructions within seven (7) days of the first date of receival
              for the nominated vessel will be subject to acceptance by Grainpac
              Melbourne.
            </li>
            <li>
              Insurance of the commodity during transit to, transit from, and
              whilst in store at Grainpac Melbourne is to remain the care and
              account of the client.
            </li>
            <li>
              Payment terms are fourteen (14) days from the end of the week of
              completion of each packing order, to approved clients – subject to
              a Grainpac Melbourne Credit Application.
            </li>
            <li>
              Grainpac Melbourne will not accept quality or other claims on
              shipments which have not been subject to independent quality
              inspection and verification prior to shipment. It is the client’s
              responsibility and cost to appoint an appropriate inspection
              service. It is the client’s responsibility to ensure residues on
              all grain tendered for packing are compliant with the MRL’s
              (Maximum Residue Limits) for the importing country or domestic
              market. As Grainpac Melbourne has no contractual relationship with
              the actual supplier of the grain, it will not be Grainpac
              Melbourne’s responsibility to request vendor declarations or
              treatment information. Any breach of MRL standards will be
              referred back to the client for action.
            </li>
            <li>
              Grainpac Melbourne has possession of the stored commodity and a
              lien in respect of any unpaid packing charges but otherwise has no
              legal or equitable title to the commodity, unless it is an owner
              of the commodity.
            </li>
          </ul>

          {/* Additional Services Section */}
          <h2 className="text-xl font-bold mt-8">Additional Services</h2>
          <table className="table-auto border-collapse w-full mt-4 mb-4">
            <thead>
              <tr>
                <th className="border px-4 py-2">Additional Services</th>
                <th className="border px-4 py-2">Details</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="border px-4 py-2">Fumigation</td>
                <td className="border px-4 py-2">
                  <ul>
                    <li>
                      Sulfuryl Fluoride / Methyl Bromide $7.00 per tonne for 20'
                      containers, $8.00 per tonne for 40' containers
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td className="border px-4 py-2">Composite Samples</td>
                <td className="border px-4 py-2">
                  <ul>
                    <li>
                      Postage/courier fees for two (2) 3kg pre-shipment and/or
                      composite samples per consignment
                    </li>
                    <li>Additional samples will be on-charged at cost</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td className="border px-4 py-2">Blending</td>
                <td className="border px-4 py-2">
                  $2.00 per tonne. Exact details of blending requirement to be
                  forwarded to Grainpac Melbourne before commencing.
                </td>
              </tr>
              <tr>
                <td className="border px-4 py-2">Penalty Carry Charge</td>
                <td className="border px-4 py-2">
                  $0.50 per tonne per day after the first fourteen (14) days.
                  Grainpac does not offer long-term warehousing services due to
                  limited storage.
                </td>
              </tr>
              <tr>
                <td className="border px-4 py-2">Receival & Outturn</td>
                <td className="border px-4 py-2">
                  $10.00 per tonne for grain received in for containerisation
                  but subsequently out-turned due to cancellation of packing
                  instructions by the client.
                </td>
              </tr>
            </tbody>
          </table>
          <a
            href={TCPDF}
            className="text-blue-600 underline"
            download="Grainpac_Melbourne_T&C.pdf"
          >
            [Download Full T&C 1st Oct 2024 (PDF)]
          </a>
        </div>
      </div>
      <h2 className="text-3xl font-bold text-center text-[#0071bc]">
        OTHER SERVICES
      </h2>
      <ServiceCards hiddenTitle="PACKING RATES" />
      <Footer />
    </div>
  );
}

export default PackingRates;
