import React, { useState, useEffect, useRef } from "react";
import CountUp from "react-countup";

const CounterSection = () => {
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);

  const counterData = [
    { end: 12, suffix: "+", text: "Years Experience" },
    { end: 45000, suffix: "+", text: "Containers Packed" },
    { end: 1.1, suffix: "M+", text: "Tonnes Packed" },
    { end: 35, suffix: "+", text: "Countries Reached" },
  ];

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(entry.target);
        }
      },
      { threshold: 0.1 }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  return (
    <div ref={sectionRef} className="bg-grey-200 py-10">
      <div className="container mx-auto w-[90%]">
        <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
          {counterData.map((item, index) => (
            <div key={index} className="text-center">
              {isVisible && (
                <CountUp
                  start={0}
                  end={item.end}
                  duration={2.5}
                  suffix={item.suffix}
                  className="text-4xl font-bold text-[#0071bc]"
                />
              )}
              <p className="mt-2 text-sm text-gray-600">{item.text}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CounterSection;
