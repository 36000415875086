import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import HomePage from "./pages/HomePage";
import About from "./pages/About";
import Services from "./pages/Services";
import PackingContainers from "./pages/PackingContainers";
import PackingRates from "./pages/PackingRates";
import ContactUs from "./pages/ContactUs";
import Sustainability from "./pages/Sustainability";

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<Services />} />
        <Route
          path="/services/packing_containers"
          element={<PackingContainers />}
        />
        <Route path="/services/packing_rates" element={<PackingRates />} />
        <Route path="/contactUs" element={<ContactUs />} />
        {/* <Route path="/sustainability" element={<Sustainability />} /> */}
      </Routes>
    </Router>
  );
}

export default App;
