import React from "react";
import { Link } from "react-router-dom";

const BreadCrumb = ({ crumbs }) => {
  const crumbKeys = Object.keys(crumbs);

  return (
    <div className="w-full flex h-10 rounded-b-lg bg-gray-200">
      {crumbKeys.map((key, index) => {
        const [label, path] = crumbs[key]; // Destructuring the array

        return (
          <div key={key} className="flex items-center">
            <Link to={path}>
              <h2 className="ml-4 p-2 font-medium text-sm md:text-base">
                {label}
              </h2>
            </Link>
            {/* Add a separator unless it's the last breadcrumb */}
            {index < crumbKeys.length - 1 && (
              <h2 className="p-2 text-sm md:text-base">{">"}</h2>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default BreadCrumb;
