import React, { useState } from "react";
import silo from "../assets/silo-art.jpg";

function ContactUs() {
  const [status, setStatus] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.target;
    const data = new FormData(form);

    try {
      const response = await fetch("https://formspree.io/f/mnnqnnrw", {
        method: "POST",
        body: data,
        headers: {
          Accept: "application/json",
        },
      });

      if (response.ok) {
        setStatus("Thanks for your submission!");
        form.reset();
      } else {
        const data = await response.json();
        if (data.errors) {
          setStatus(data.errors.map((error) => error.message).join(", "));
        } else {
          setStatus("Oops! There was a problem submitting your form");
        }
      }
    } catch (error) {
      setStatus("Oops! There was a problem submitting your form");
    }
  };

  return (
    <div className="max-w-[1300px] mx-auto">
      <div className="bg-white border border-gray-100 rounded-lg shadow-lg overflow-hidden">
        <div className="flex flex-col lg:flex-row gap-6">
          {/* Contact Form Card */}
          <div className="w-full lg:w-[65%] flex flex-col justify-between p-8">
            <h2 className="text-2xl font-bold text-[#0071bc] mb-6">
              CONTACT US
            </h2>
            <form onSubmit={handleSubmit} className="flex-grow flex flex-col">
              <div className="grid grid-cols-2 gap-6 mb-6">
                <div>
                  <label
                    htmlFor="firstName"
                    className="block text-sm font-medium text-gray-700 mb-1"
                  >
                    First name *
                  </label>
                  <input
                    type="text"
                    id="firstName"
                    name="firstName"
                    className="w-full border border-gray-300 rounded-md px-3 py-2 text-base"
                    required
                  />
                </div>
                <div>
                  <label
                    htmlFor="lastName"
                    className="block text-sm font-medium text-gray-700 mb-1"
                  >
                    Last name *
                  </label>
                  <input
                    type="text"
                    id="lastName"
                    name="lastName"
                    className="w-full border border-gray-300 rounded-md px-3 py-2 text-base"
                    required
                  />
                </div>
              </div>
              <div className="grid grid-cols-2 gap-6 mb-6">
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700 mb-1"
                  >
                    Your email *
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    className="w-full border border-gray-300 rounded-md px-3 py-2 text-base"
                    required
                  />
                </div>
                <div>
                  <label
                    htmlFor="Mobile"
                    className="block text-sm font-medium text-gray-700 mb-1"
                  >
                    Mobile
                  </label>
                  <input
                    type="text"
                    id="mobile"
                    name="mobile"
                    className="w-full border border-gray-300 rounded-md px-3 py-2 text-base"
                  />
                </div>
                <div>
                  <label
                    htmlFor="subject"
                    className="block text-sm font-medium text-gray-700 mb-1"
                  >
                    Subject
                  </label>
                  <input
                    type="text"
                    id="subject"
                    name="subject"
                    className="w-full span border border-gray-300 rounded-md px-3 py-2 text-base"
                  />
                </div>
              </div>
              <div className="mb-6 flex-grow">
                <label
                  htmlFor="message"
                  className="block text-sm font-medium text-gray-700 mb-1"
                >
                  Message
                </label>
                <textarea
                  id="message"
                  name="message"
                  rows="4"
                  className="w-full border border-gray-300 rounded-md px-3 py-2 text-base"
                  required
                ></textarea>
              </div>
              <button
                type="submit"
                className="bg-[#0071bc] text-white px-5 py-2 rounded-md hover:bg-green-700 transition duration-300 text-base"
              >
                Submit
              </button>
              {status && <p className="mt-3 text-sm">{status}</p>}
            </form>
          </div>

          {/* Image Card */}
          <div className="w-full lg:w-[50%] flex items-center justify-center p-5">
            <img
              src={silo}
              alt="Container ship"
              className="w-full h-auto object-cover rounded-lg mb-4 lg:mb-0"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
