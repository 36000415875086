import React from "react";
import logo from "../assets/logo.png"; // Make sure the path is correct
import { Link } from "react-router-dom";
function Footer() {
  return (
    <footer className="bg-[#868a89] text-white py-12">
      <div className="container mx-auto w-[90%]">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          <div>
            <Link to="/">
              <img
                src={logo}
                alt="Mahonys Transport Services Logo"
                className="w-40 mb-4"
              />
            </Link>
            <p className="text-sm">
              Seamless solution for efficient packing & storage
            </p>
          </div>
          <div>
            <h3 className="font-bold text-lg mb-4">Quick Links</h3>
            <ul className="space-y-2">
              <li>
                <a href="/" className="hover:underline">
                  Home
                </a>
              </li>
              <li>
                <a href="/about" className="hover:underline">
                  About Us
                </a>
              </li>
              <li>
                <a href="/services" className="hover:underline">
                  Services
                </a>
              </li>
              <li>
                <a href="/contactUs" className="hover:underline">
                  Contact
                </a>
              </li>
            </ul>
          </div>
          <div>
            <h3 className="font-bold text-lg mb-4">Services</h3>
            <ul className="space-y-2">
              <li>
                <a
                  href="/services/packing_containers"
                  className="hover:underline"
                >
                  Packing Containers
                </a>
              </li>
              <li>
                <a href="/services/packing_rates" className="hover:underline">
                  Packing Rates
                </a>
              </li>
            </ul>
          </div>
          <div>
            <h3 className="font-bold text-lg mb-4">Contact Us</h3>
            <p className="mb-2">76 Pipe Rd, Laverton North VIC 3026</p>
            <p className="mb-2">Phone: 03 8360 7566</p>
            {/* <p className="mb-2">Fax: 03 8360 7399</p>
            <p className="mb-2">Mobile: 0459 607 566</p> */}
            <p className="mt-2">
              <a href="mailto:enquiries@grainpac.com.au">
                enquiries@grainpac.com.au
              </a>
            </p>
            <h4> Grainpac (ABN 80 161 691 952)</h4>
            {/* <div className="flex space-x-4 mt-4">
              <a
                href="https://www.facebook.com/mahonystransport"
                className="hover:text-gray-300"
              >
                <img
                  className="h-6 w-6 rounded-full"
                  alt="Facebook"
                  src={FacebookLogo}
                ></img>
              </a>

              <a
                href="https://www.linkedin.com/company/mahonys-transport/posts/?feedView=all"
                className="hover:text-gray-300"
              >
                <img
                  className="h-6 w-6 rounded-full"
                  alt="LinkedIn"
                  src={LinkedInLogo}
                ></img>
              </a>
            </div> */}
          </div>
        </div>
        <div className="border-t border-white/20 mt-8 pt-8 text-center">
          <p>&copy; 2024 Grainpac Melbourne. All rights reserved.</p>
        </div>

        <div className=" border-white/20 pt-8 text-right">
          <p>Website By</p>
          <a href="https://clutchit.com.au/" className="hover:underline">
            Clutch Australia
          </a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
