import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../assets/logo.png"; // Import the logo

const NavBar = () => {
  const location = useLocation();
  const [activeButton, setActiveButton] = useState("");
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isServicesDropdownOpen, setIsServicesDropdownOpen] = useState(false);
  const [isAboutUsDropdownOpen, setIsAboutUsDropdownOpen] = useState(false);

  useEffect(() => {
    const path = location.pathname;
    if (path === "/") setActiveButton("Home");
    else if (path.includes("/services")) setActiveButton("Services");
    else if (path === "/compliance") setActiveButton("Compliance");
    // else if (path === "/sustainability") setActiveButton("Sustainability");
    else if (path === "/contact") setActiveButton("Contact Us");
    else if (path === "/about") setActiveButton("About Us");
  }, [location]);

  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
    setIsMenuOpen(false); // Close menu on click
    setIsServicesDropdownOpen(false);
  };

  // 0071bc -> blue
  // 92b912 -> Green
  const getButtonClasses = (buttonName) => {
    const baseClasses = "px-3 py-1.5 rounded-xl text-sm font-medium";
    if (buttonName === activeButton) {
      return buttonName === "Contact Us"
        ? `${baseClasses} bg-[#92b912] text-white`
        : `${baseClasses} bg-black text-white`;
    }
    if (buttonName === "Sustainability") {
      return `${baseClasses} bg-[#92b912] text-white hover:bg-black`;
    }
    if (buttonName === "Contact Us") {
      return `${baseClasses} bg-black text-white hover:bg-[#0071bc]`;
    }
    return `${baseClasses} text-black hover:bg-gray-200`;
  };

  const services = [
    { name: "Packing Container", path: "/services/packing_containers" },
    { name: "Packing Rates", path: "/services/packing_rates" },
    // Add more services as needed
  ];

  const aboutUsPages = [
    { name: "Our Story", path: "/about" },
    { name: "Our Mission", path: "/about" },
    { name: "Our Values", path: "/about" },
    // Add more pages as needed
  ];

  const toggleServicesDropdown = () => {
    setIsServicesDropdownOpen(!isServicesDropdownOpen);
    setIsAboutUsDropdownOpen(false); // Close About Us dropdown
  };

  const toggleAboutUsDropdown = () => {
    setIsAboutUsDropdownOpen(!isAboutUsDropdownOpen);
    setIsServicesDropdownOpen(false); // Close Services dropdown
  };

  return (
    <nav className="py-1">
      <div className="w-[100%] mx-auto px-4 sm:px-8 lg:px-8 bg-white shadow-lg">
        <div className="flex items-center justify-between h-16">
          {/* Left section - Hamburger for mobile */}
          <div className="flex items-center lg:flex-1">
            <button
              className="lg:hidden text-black focus:outline-none"
              onClick={() => setIsMenuOpen(!isMenuOpen)}
            >
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d={
                    isMenuOpen
                      ? "M6 18L18 6M6 6l12 12"
                      : "M4 6h16M4 12h16M4 18h16"
                  }
                ></path>
              </svg>
            </button>

            {/* Navigation links for large screens */}
            <div className="hidden lg:flex justify-left space-x-3">
              <Link to="/">
                <button
                  onClick={() => handleButtonClick("Home")}
                  className={getButtonClasses("Home")}
                >
                  Home
                </button>
              </Link>
              <div className="relative">
                <button
                  onClick={toggleServicesDropdown}
                  className={getButtonClasses("Services")}
                >
                  Services
                </button>
                {isServicesDropdownOpen && (
                  <div className="absolute z-10 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                    <div
                      className="py-1"
                      role="menu"
                      aria-orientation="vertical"
                      aria-labelledby="options-menu"
                    >
                      {services.map((service) => (
                        <Link
                          key={service.name}
                          to={service.path}
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                          role="menuitem"
                          onClick={() => handleButtonClick("Services")}
                        >
                          {service.name}
                        </Link>
                      ))}
                    </div>
                  </div>
                )}
              </div>
              <div className="relative">
                <button
                  onClick={toggleAboutUsDropdown}
                  className={getButtonClasses("About Us")}
                >
                  About Us
                </button>
                {isAboutUsDropdownOpen && (
                  <div className="absolute z-10 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                    <div
                      className="py-1"
                      role="menu"
                      aria-orientation="vertical"
                      aria-labelledby="options-menu"
                    >
                      {aboutUsPages.map((page) => (
                        <Link
                          key={page.name}
                          to={page.path}
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                          role="menuitem"
                          onClick={() => handleButtonClick("About Us")}
                        >
                          {page.name}
                        </Link>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* Center section - Logo */}
          <div className="flex-grow flex items-center justify-center">
            <Link to="/">
              <img
                className="h-14 w-auto sm:h-15"
                src={logo}
                alt="Grainpac Melbourne"
              />
            </Link>
          </div>

          {/* Right section - For large screens */}
          <div className="flex items-center lg:flex-1 justify-end space-x-3">
            <Link to="/sustainability">
              <button
                onClick={() => handleButtonClick("Sustainability")}
                className="hidden"
              >
                Sustainability
              </button>
            </Link>
            <Link to="/contactUs">
              <button
                onClick={() => handleButtonClick("Contact Us")}
                className={`${getButtonClasses("Contact Us")} hidden lg:block`} // Hide in mobile, show on large screens
              >
                Contact Us
              </button>
            </Link>
          </div>
        </div>

        {/* Mobile Menu */}
        {isMenuOpen && (
          <div className="lg:hidden mt-3 pb-4 flex flex-col items-center space-y-3">
            <Link to="/">
              <button
                onClick={() => handleButtonClick("Home")}
                className={getButtonClasses("Home")}
              >
                Home
              </button>
            </Link>
            <button
              onClick={toggleServicesDropdown}
              className={getButtonClasses("Services")}
            >
              Services
            </button>
            {isServicesDropdownOpen && (
              <div className="flex flex-col border-b-2 items-center space-y-2">
                {services.map((page) => (
                  <Link
                    key={page.name}
                    to={page.path}
                    className="text-sm text-[#92b912] hover:text-gray-900"
                    onClick={() => handleButtonClick("Services")}
                  >
                    {page.name}
                  </Link>
                ))}
              </div>
            )}
            <button
              onClick={toggleAboutUsDropdown}
              className={getButtonClasses("About Us")}
            >
              About Us
            </button>
            {isAboutUsDropdownOpen && (
              <div className="flex flex-col border-b-2 items-center space-y-2">
                {aboutUsPages.map((page) => (
                  <Link
                    key={page.name}
                    to={page.path}
                    className="text-sm text-[#92b912] hover:text-gray-900"
                    onClick={() => handleButtonClick("About Us")}
                  >
                    {page.name}
                  </Link>
                ))}
              </div>
            )}
            <Link to="/contactUs">
              <button
                onClick={() => handleButtonClick("Contact Us")}
                className={getButtonClasses("Contact Us")}
              >
                Contact Us
              </button>
            </Link>
          </div>
        )}
      </div>
    </nav>
  );
};

export default NavBar;
