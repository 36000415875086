import React from "react";
import NavBar from "../components/navbar";
import Footer from "../components/footer";
import aboutUsBg from "../assets/grainpac.jpg";
import BreadCrumb from "../components/BreadCrumb";

import Hero from "../components/Hero";

function About() {
  const crumb = {
    Home: ["Home", "/"],
    About: ["About Us", "/about"],
  };

  return (
    <div className="bg-white relative">
      {/* Hero Section with NavBar overlay */}
      <Hero
        type={"sub"}
        img={aboutUsBg}
        title={
          <h2>
            ABOUT
            <br />
            GRAINPAC
            <br />
            MELBOURNE
          </h2>
        }
        subText={
          <p className="text-white text-l mb-10">
            Experts in the grain packing business
            <br />
            Providing professional, quality contract packing and fumigation
            services
          </p>
        }
        crumb={crumb}
      />

      {/* Main Content */}
      <div className="container mx-auto py-12 px-10 w-[90%]">
        <h2 className="text-3xl font-bold mb-6 text-[#0071bc]">Our Story</h2>
        <p className="mb-6">
          Grainpac Melbourne was incorporated in December 2012 for the purposes
          of acquiring and operating the 4,000 m2 bulk containerisation facility
          at 76 Pipe Road in Laverton North, Melbourne.
        </p>
        <p className="mb-6">
          In June 2013, the Department of Agriculture approved Grainpac
          Melbourne as an Export Registered Establishment (No. 1318). Packing
          operations at Grainpac Melbourne commenced on 18 July 2013, following
          the installation and commissioning of a new full sized 95 tonne
          weighbridge.
        </p>
        <p className="mb-6">
          The fully asphalted site currently has a storage capacity in excess of
          2,000 tonnes and is equipped with a 15 tonne in-ground drive over
          receival pit complete with a 250 tonnes per hour auger to maximise
          throughput and increase receival capacity. Grainpac Melbourne can pack
          both 20’ and 40’ containers and has a fully equipped classification
          stand, office facilities and new ablutions building.
        </p>
        <p className="mb-6">
          Grainpac Melbourne receives grain from up country warehouses and farm
          storages, packs it bulk into shipping containers and delivers the
          containers to the port for shipping. A third-party carrier is engaged
          to collect empty containers, bring the containers to the Grainpac
          Melbourne site for packing and transport full shipping containers to
          the port. This activity of packing grain is carried out on a contract
          basis as a service to Australian grain exporters.
        </p>
        <h2 className="text-3xl font-bold mb-6 text-[#0071bc]">Our Mission</h2>
        <p className="mb-4">
          Grainpac Melbourne operates independently and does not own or trade
          grain. The business must comply with strict quarantine guidelines and
          government regulations while handling grain for export. The business
          must also follow self-imposed and clearly defined systems and
          procedures to meet the demands of exporters and to mitigate potential
          claims against Grainpac Melbourne and or its clients.
        </p>
        <p className="mb-4">
          Grainpac is currently small and nimble with low operational costs. It
          is well located for grain receivals from right across Victoria and
          southern NSW and within close proximity to the ports.
        </p>
        <p className="mb-4">
          Grainpac Melbourne has matured into a stable business having
          established an excellent reputation for delivering high quality grain
          packing services.
        </p>
        <p className="mb-6">
          A major upgrade of the Grainpac silo system is planned for late 2024,
          with the floor of the main silo to be concreted, together with the
          installation of a new zero entry sweep auger and full length drag
          conveyor. These developments will not only increase productivity from
          the silo but also reduce manual handling risks with operating the
          current sweep.
        </p>

        <h2 className="text-3xl font-bold mb-6 text-[#0071bc]">Our Values</h2>
        <ul className="list-disc list-inside mb-6">
          <li>Integrity in all our dealings</li>
          <li>Commitment to excellence</li>
          <li>Innovation in logistics solutions</li>
          <li>Environmental responsibility</li>
          <li>Customer-centric approach</li>
        </ul>
      </div>

      {/* Footer */}
      <Footer />
    </div>
  );
}

export default About;
