import React from "react";
import NavBar from "../components/navbar";
import Footer from "../components/footer";
import ContactUsCard from "../components/ContactUs";
import "@fortawesome/fontawesome-free/css/all.min.css";
import sitePic from "../assets/Site_Layout.jpg";

const Contact_Us = () => {
  return (
    <div>
      <NavBar />

      <div className="max-w-[1300px] mx-auto my-5 flex justify-center lg:justify-end">
        <a
          href="tel:0383607566" // Use 'tel:' protocol
          className="flex items-center px-2 py-2 bg-[#0071bc] text-white font-normal rounded-md shadow-md hover:bg-black transition duration-200"
        >
          <i className="fas fa-phone-alt mr-2"></i> {/* Phone icon */}
          03 8360 7566
        </a>
      </div>
      <div className="max-w-[1300px] mx-auto my-10">
        <div className="flex flex-col lg:flex-row gap-8 mb-8">
          <div className="flex-grow flex">
            <ContactUsCard />
          </div>

          {/* Address Card */}
          <div className="w-full lg:w-1/3 bg-white rounded-lg shadow-lg p-6 flex flex-col">
            <h3 className="text-lg lg:text-xl font-semibold text-[#0071bc] mb-4">
              Melbourne Address
            </h3>

            <address className="not-italic text-sm lg:text-base flex-grow">
              <p>76 Pipe Rd</p>
              <p>Laverton North</p>
              <p>Victoria, 3026</p>
              {/* <p className="mt-2">Mobile: +61 459 607 566</p> */}
              <p className="mt-2">Phone: 03 8360 7566</p>
              {/* <p className="mt-2">Fax: 03 8360 7399</p> */}
              <p className="mt-2">
                <a href="mailto:enquiries@grainpac.com.au">
                  enquiries@grainpac.com.au
                </a>
              </p>
              <h4> Grainpac (ABN 80 161 691 952)</h4>
            </address>
            <div className="mt-4 flex-grow">
              <img
                src={sitePic}
                alt="Grainpac Melbourne Location"
                className="w-full h-full object-cover rounded-lg shadow-md"
              />
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Contact_Us;
