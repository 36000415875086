import React, { useState, useEffect } from "react";
import NavBar from "../components/navbar";
import CounterSection from "../components/CounterSection";
import Accreditations from "../components/Accreditations"; // Import the Accreditations component
import ContactUs from "../components/ContactUs"; // Import the new ContactUs component
import heroImage from "../assets/grainpac.jpg";

// Import service images
import siteSilo from "../assets/photo-2.png";
import siteOffice from "../assets/photo-3.png";
import packing from "../assets/photo-1.png";

import Footer from "../components/footer";

import { Link } from "react-router-dom";
import { ServiceCards } from "./Services";

function HomePage() {
  return (
    <div className="bg-white relative">
      {/* Hero Section with NavBar overlay */}
      <div className=" relative h-[99vh] mx-auto max-w-[97rem] p-5 ">
        <div className="rounded-lg overflow-hidden h-full relative">
          <img
            src={heroImage}
            alt="Grainpac melbourne"
            className="w-full h-full object-fit absolute inset-0 max-[700px]:object-cover"
          />
          <div className="absolute inset-0 bg-black bg-opacity-30">
            {/* NavBar positioned absolutely within the hero section */}
            <div className="absolute top-0 left-0 right-0 z-10">
              <NavBar />
            </div>

            <div className="h-full flex flex-col justify-between px-4 sm:px-10 py-10 ml-2 sm:ml-5">
              <div>
                <h1 className="text-white text-6xl sm:text-xl lg:text-7xl font-bold mb-4 mt-32 sm:mt-[140px] leading-tight">
                  Grainpac
                </h1>
                <h3 className="text-white text-4xl sm:text-4xl lg:text-2xl font-normal ml-1 leading-tight">
                  Melbourne
                </h3>
              </div>
              <p className="text-white text-sm sm:text-base lg:text-lg mb-6 sm:mb-10">
                Experts in the grain packing business
                <br />
                Providing professional, quality contract packing and fumigation
                services
              </p>
            </div>
          </div>

          <div className="hidden sm:absolute sm:top-40 sm:right-5 sm:bg-opacity-50 sm:text-white sm:p-4 sm:max-w-xs sm:rounded-lg sm:block">
            <p className="text-sm">
              Grainpac Melboune Draws On Years Of Experience In The Agricultural
              Industry To Provide The Highest Quality Services.
            </p>
          </div>
        </div>
      </div>

      {/* Our Services Section */}
      <div className="container mx-auto py-6 w-[90%]">
        <h2 className="text-3xl font-bold text-center mb-8 text-[#0071bc]">
          OUR SERVICES
        </h2>
        <ServiceCards />
      </div>

      {/* About Mahonys Section */}
      <div className="bg-gray-100 py-12">
        <div className="container mx-auto w-[90%]">
          <h2 className="text-2xl font-bold mb-4 text-black">
            About Grainpac Melbourne
          </h2>
          <p className="text-base mb-4 font-medium text-[#0071bc]">
            New modern and fully equipped grain packing and fumigation
            facilities located at Laverton North, VIC
          </p>
          <p className="text-sm mb-4">
            Grainpac Melbourne is a leading Department of Agriculture accredited
            storage, fumigation and packing facility located in Pipe Road
            Laverton, with easy access to the port of Melbourne. In June 2013,
            the Department of Agriculture approved Grainpac Melbourne as an
            Export Registered Establishment (No. 1318). Packing operations at
            Grainpac Melbourne commenced on 18 July 2013, following the
            installation and commissioning of a new full sized 95 tonne
            weighbridge. The fully asphalted site currently has a storage
            capacity in excess of 2,000 tonnes and is equipped with a 15 tonne
            in-ground drive over receival pit complete with a 250 tonnes per
            hour auger to maximise throughput and increase receival capacity.
            Grainpac Melbourne can pack both 20’ and 40’ containers and has a
            fully equipped classification stand, office facilities and new
            ablutions building.
          </p>
          <p className="text-sm mb-4">
            The site has proven itself capable of handling a wide range of
            commodities and continues to test new commodities and implement
            improvements to provide a comprehensive range of services to a broad
            market. Our premises boasts a dual lane traffic thoroughfare and a
            private full sized weighbridge along with new modern, fully
            equipped, Grain Trade Australia (GTA) compliant classification stand
            and office facilities. Recent upgrades to the site include a new 12
            tonne below ground intake hopper, a new intake auger and complete
            resurfacing of the yard.
          </p>
          <p className="text-sm mb-4">
            Grainpac prides itself on providing efficient, reliable and
            transparent services and does so by working closely with its
            customers.
          </p>
          <Link
            to="/about"
            className="text-slate-500 font-bold hover:underline"
          >
            More About Us &gt;
          </Link>
        </div>
      </div>

      {/* Counter Section */}
      <CounterSection />

      {/* Accreditations Section (replacing News Section) */}
      <Accreditations />

      {/* Logistics & Management Solutions Section */}
      <div className="container mx-auto py-12 w-[90%]">
        <div className="mb-8  p-4 inline-block">
          <h2 className="text-3xl font-bold text-[#0071bc] mb-2">
            STORAGE & MANAGEMENT SOLUTIONS
          </h2>
          <p className="text-gray-600">
            So that you can easily and rapidly manage inventory and orders.
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div className="rounded-lg overflow-hidden h-64">
            {" "}
            {/* Set a fixed height */}
            <img
              src={siteSilo}
              alt="Truck on road"
              className="w-full h-full object-cover"
            />
          </div>
          <div className="rounded-lg overflow-hidden h-64">
            {" "}
            {/* Set a fixed height */}
            <img
              src={packing}
              alt="Trucks in warehouse"
              className="w-full h-full object-cover"
            />
          </div>
          <div className="rounded-lg overflow-hidden h-64 relative">
            {" "}
            {/* Set a fixed height and relative positioning */}
            <img
              src={siteOffice}
              alt="Kenworth truck"
              className="w-full h-[120%] object-cover absolute bottom-0"
            />
          </div>
        </div>

        <div className=" py-20">
          {/* Contact Form */}
          <ContactUs />
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default HomePage;
