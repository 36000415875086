import React from "react";
import NavBar from "./navbar";
import BreadCrumb from "./BreadCrumb";

const Hero = ({ type, img, title, subText, crumb }) => {
  // Use let to allow reassignment
  let containerHeight = "99vh"; // Default height

  // Set height based on the type prop
  if (type === "sub") {
    containerHeight = "60vh";
  }

  return (
    <div
      className="relative mx-auto max-w-[97rem] p-5"
      style={{ height: containerHeight }}
    >
      <div className="rounded-t-lg overflow-hidden h-full relative">
        <img
          src={img}
          alt="About Mahonys Transport Services"
          className="w-full h-full object-cover absolute inset-0"
        />
        <div className="absolute inset-0 bg-black bg-opacity-30">
          {/* NavBar positioned absolutely within the hero section */}
          <div className="absolute top-0 left-0 right-0 z-10">
            <NavBar />
          </div>

          <div className="h-full flex flex-col px-10 ml-5">
            <h1 className="text-white text-4xl sm:text-5xl lg:text-6xl font-bold mb-4 mt-[140px]">
              {title}
            </h1>
            <p className="text-white text-base sm:text-lg mb-10">{subText}</p>
          </div>
        </div>
        <div className="hidden sm:absolute sm:top-40 sm:right-5 sm:bg-opacity-50 sm:text-white sm:p-4 sm:max-w-xs sm:rounded-lg sm:block">
          <p className="text-sm">
            Grainpac Melbourne: A legacy of quality and reliability in the
            agricultural industry.
          </p>
        </div>
      </div>
      {crumb && <BreadCrumb crumbs={crumb} />}
    </div>
  );
};

export default Hero;
