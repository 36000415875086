import React from "react";
import Footer from "../components/footer";
import importExportImage from "../assets/trucks-packing.png";

import { Link } from "react-router-dom";
import { ServiceCards } from "./Services";
import Hero from "../components/Hero";

function ImportExport() {
  const crumb = {
    Home: ["Home", "/"],
    Services: ["Services", "/services"],
    ImportExport: ["Packing Containers", "/services/packing_containers"],
  };

  return (
    <div className="bg-white relative">
      {/* Hero Section with NavBar overlay */}
      <Hero
        type={"sub"}
        img={importExportImage}
        title={"PACKING CONTAINERS"}
        subText={"Seamless Container Solutions for Global Trade Efficiency"}
        crumb={crumb}
      />

      {/* Main Content */}
      <div className="container mx-auto py-12 px-10 w-[90%]">
        {/* <h2 className="text-3xl font-bold mb-6 text-[#0071bc]">
          Import Bulk Commodity Containers
        </h2>
        <p className="mb-6">
          Grainpac Melbourne is able to provide a high quality service Importing
          all bulk and containerised bi-products, stock feeds and fertilisers.
          These will be quarantined in accordance with QAP Class 2.3 standards
          and regulations.
        </p>
        <p className="mb-6">
          Grainpac Melbourne has a registered Quarantine Approval Premises (QAP)
          fully licenced and accredited with Department of Agriculture,
          Fisheries and Forestry (DAFF) to handle all commodity container
          Importing (QAP Class 2.3). Grainpac has the capacity to handle large
          imported tonnages and safely store it for extended periods of time on
          our site.
        </p>
        <p className="mb-6">
          Once the commodity passes the QAP Class 2.3 standards and regulations,
          Grainpac Melbourne also have the ability to deliver your bulk product
          timely and cost efficiently to your businesses designated
          destination(s) with our modern Tipper fleet.
        </p>
        <p className="mb-6">
          For more information on Mahonys Bulk and Container Import capabilities
          and prices call our Business Development Manager or our Melbourne
          office.
        </p>
        <Link
          to="/contactUs"
          className="text-[#92b912] font-bold hover:underline"
        >
          Contact Us &gt;
        </Link> */}

        <h2 className="text-3xl font-bold mb-6 mt-6 text-[#0071bc]">
          Container Packing (Export Containers)
        </h2>
        <p className="mb-6">
          Grainpac Melbourne offers specialized container packing services for
          both domestic and export needs. Our fully AQIS accredited site is
          strategically positioned within the heart of the container industry,
          allowing us to meet your requirements efficiently.
        </p>
        <p className="mb-6">
          We pride ourselves on being a boutique packer that focuses on quality
          over quantity. Our commitment to quality ensures that your products
          are handled with precision and care throughout the packing process.
          Grainpac Melbourne has matured into a stable business, establishing an
          excellent reputation for delivering high-quality grain packing
          services.
        </p>

        <h2 className="text-3xl font-bold mb-6 mt-10 text-[#0071bc]">
          Competitive Rates for Agricultural Commodities
        </h2>
        <p className="mb-6">
          Our expertise in grain packing, combined with our fully equipped
          facilities, allows us to provide competitive rates and a variety of
          services, including:
        </p>
        <ul className="list-disc ml-6 mt-4">
          <li>Packing</li>
          <li>Fumigation</li>
          <li>Blending</li>
        </ul>
        <p className=" mt-4 mb-6">
          Grainpac Pty Ltd is a Department of Agriculture Export Registered
          Establishment No. 1318, located in the Department of Agriculture
          Region "LAV" (Laverton North).
        </p>
        <p className="mb-6">
          For more information about our services, please contact our Business
          Manager:
        </p>
        <div className="max-w-[1300px] my-5 flex justify-center lg:justify-start space-x-4">
          <a
            href="tel:0383607566" // Use 'tel:' protocol
            className="flex items-center px-2 py-2 bg-[#0071bc] text-white font-normal rounded-md shadow-md hover:bg-black transition duration-200"
          >
            <i className="fas fa-phone-alt mr-2"></i> {/* Phone icon */}
            Call Us
          </a>
          <Link to="/contactUs">
            <button className="flex items-center px-4 py-2 bg-black text-white font-normal rounded-md shadow-md hover:bg-black transition duration-200">
              Contact Us
            </button>
          </Link>
        </div>
      </div>
      <h2 className="text-3xl font-bold text-center text-[#0071bc]">
        OTHER SERVICES
      </h2>
      <ServiceCards hiddenTitle="PACKING CONTAINERS" />

      {/* Footer */}
      <Footer />
    </div>
  );
}

export default ImportExport;
