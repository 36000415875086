import React from "react";
import Slider from "react-slick";
import AQIS from "../assets/DAFF_logo.jpeg";
import MFDS from "../assets/MFDS.png";
import GACC from "../assets/GACC.png";
import GIAV from "../assets/GIAV.jpg";

// Import CSS files for react-slick
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Accreditations = () => {
  const accreditations = [
    {
      icon: AQIS,
      title: "DAFF Export Accreditation",
      description: "Export Registered Site: 1318",
    },
    {
      icon: MFDS,
      title: "Korean Ministry of Food and Drug Safety",
      description: "Foreign Food Facility Registration AU0000297",
    },
    {
      icon: GACC,
      title: "General Administration of Customs PRC",
      description: "Registrations CAUS10022112010-012",
    },
    {
      icon: GIAV,
      title: "Grain Industry Association of Victoria",
      description: "Member",
    },
  ];

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section className="bg-[#8b9862] py-12 px-4">
      <h2 className="text-3xl font-bold text-center text-gray-100 mb-8">
        ACCREDITATIONS AND CERTIFICATIONS
      </h2>
      <div className="max-w-8xl  mx-auto">
        <Slider {...settings} className="!flex h-200 ">
          {accreditations.map((accreditation, index) => (
            <div key={index} className="px-2 h-full py-5">
              <div className="bg-gray-100 text-black p-6 rounded-lg shadow-md flex flex-col items-center text-center h-full transition-all duration-300 ease-in-out transform hover:scale-105 hover:shadow-lg">
                <div className="w-16 h-16 bg-white flex items-center justify-center mb-4">
                  <img
                    src={accreditation.icon}
                    alt={accreditation.title}
                    className="w-16 h-16 object-contain"
                  />
                </div>
                <h3 className="text-xl font-semibold mb-2">
                  {accreditation.title}
                </h3>
                <p className="text-sm mb-2 flex-grow">
                  {accreditation.description}
                </p>
                <p className="text-xs italic mt-auto">{accreditation.source}</p>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default Accreditations;
