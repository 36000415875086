import React from "react";
import Footer from "../components/footer";
import heroImage from "../assets/grainpac.jpg";
import importExportImage from "../assets/trucks-packing.png";
import grain from "../assets/Grain.jpg";
import { Link } from "react-router-dom";

import Hero from "../components/Hero";

export const ServiceCards = ({ hiddenTitle }) => {
  const services = [
    {
      image: importExportImage,
      title: "PACKING CONTAINERS",
      msg: "Import Bulk Commodity Containers Grainpac Melbourne is able to provide a high quality service Importing all bulk and containerised bi-products, stock feeds and fertilisers.",
      link: "packing_containers",
      crumb: {
        Home: ["Home", "/"],
        Services: ["Services", "/services"],
        ImportExport: ["Packing Containers", "/services/packing_containers"],
      },
    },
    {
      image: grain,
      title: "PACKING RATES",
      msg: "Grainpac Melbourne is a small, nimble operation with low overheads and within close proximity to the ports. We offer efficient and reliable packing services at affordable rates, ensuring top-quality service for all your bulk and containerized products.",
      link: "packing_rates",
      crumb: {
        Home: ["Home", "/"],
        Services: ["Services", "/services"],
        PackingRates: ["Packing Rates", "/services/packing_rates"],
      },
    },
  ];

  // Filter out the service with the title that matches hiddenTitle
  const filteredServices = services.filter(
    (service) => service.title !== hiddenTitle
  );

  return (
    <div className="container mx-auto my-10">
      <div
        className={`grid gap-1 ${
          filteredServices.length === 1
            ? "grid-cols-1 justify-center max-w-xl mx-auto"
            : "grid-cols-1 md:grid-cols-2"
        }`}
      >
        {filteredServices.map((service, index) => (
          <Link to={`/services/${service.link}`} key={index}>
            <div className="flex flex-col items-center group mb-4 cursor-pointer">
              <div className="relative overflow-hidden rounded-lg mb-4 w-[70%] h-64">
                <img
                  src={service.image}
                  alt={service.title}
                  className="w-full h-full object-cover transition-transform duration-300 group-hover:scale-110"
                />
                <div className="absolute inset-0 bg-black bg-opacity-50 opacity-0 group-hover:opacity-90 transition-opacity duration-300 flex items-center justify-center">
                  <p className="text-white text-center px-4">{service.msg}</p>
                </div>
              </div>
              <p className="font-bold text-center">{service.title}</p>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

function Services() {
  const crumb = {
    Home: ["Home", "/"],
    Services: ["Services", "/services"],
  };

  return (
    <div className="bg-white relative">
      {/* Hero Section with NavBar overlay */}
      <Hero
        type={"sub"}
        img={heroImage}
        title={"Services"}
        subText={"What we do and what can you expect from us"}
        crumb={crumb}
      />

      {/* Main Content */}
      <h2 className="text-3xl my-14 font-bold text-center text-[#0071bc]">
        OUR SERVICES
      </h2>
      <ServiceCards />

      {/* Footer */}
      <Footer />
    </div>
  );
}

export default Services;
